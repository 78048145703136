



































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

declare var google: any

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Prop({ default: false }) tagLocationDialog
  @Prop({
    default: () => {
      return {}
    },
  })
  editorHandler

  autocompleteService = new google.maps.places.AutocompleteService()
  searchInput = ''

  @Watch('tagLocationDialog', { immediate: true }) valueChange(value) {
    if (value) {
      this.searchInput = ''
    }
  }

  changeInput(input) {
    this.autocompleteService.getQueryPredictions(
      { input },
      this.editorHandler.changeSearchLocationResult.bind(this.editorHandler)
    )
  }
}
